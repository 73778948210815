import { useWeb3React } from '@web3-react/core';
import { Grid } from 'antd';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import revelation1 from 'assets/revelation1.png';
import nftItem1 from 'assets/nft-item1.png';
import nftItem2 from 'assets/nft-item2.png';
import nftItem3 from 'assets/nft-item3.png';
import nftItem5 from 'assets/nft-item5.png';
import nftItem10 from 'assets/nft-item10.png';
import iconWallet from 'assets/Icon_Wallet.png';
import iconError from 'assets/error-icon.png';
import styled from 'styled-components';
import { fetchNFTMintAsync } from 'state/action';
import { useNFTMarketplaceState, useNFTMintState } from 'state/hooks';
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import { useRevelation } from 'hooks/useContract';
import useWalletModal from 'widgets/WalletModal/useWalletModal';
import { AiOutlineClose } from 'react-icons/ai';
import showRamp from '../../api/utils/ramp';
import { Button } from 'components/Button';

import magicNFTContractJson from '../../constants/ABI/MagicNFT.json';

import { GiBlackKnightHelm } from 'react-icons/gi';

import discount from '../../assets/15discount.png';

// styles
import './index.scss';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { CloseIcon } from 'components/Svg';
import CollectionButton from 'components/CollectionButton/CollectionButton';

import Web3 from 'web3';
import HelpModal from 'components/HelpModal/HelpModal';
import GasFeeHelpContainer from 'components/GasFeeHelpContainer';

const E18 = BigNumber.from(10).pow(18);

const NFTMint = (props) => {
  const { account } = useWeb3React();
  const [itemIndex, setItemIndex] = useState(3);
  const [isMinting, setIsMinting] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0.15);
  const [txHash, setTxHash] = useState('');
  const [alertType, setAlertType] = useState(0);
  const [isUtilitiesModal, setIsUtilitiesModal] = useState(false);
  const magicNFTContract = useRevelation();
  const breakpoint = Grid.useBreakpoint();
  const { login, logout } = useAuth();
  const [nftAvailable, setNftAvailable] = useState(0);
  const { onPresentConnectModal } = useWalletModal(login, logout);
  const dispatch = useDispatch();
  const { collection } = useNFTMarketplaceState();
  const { publicSale, whitelistSale, publicPrice, whiteListPrice } =
    useNFTMintState();

  const publicMintPrice =
    BigNumber.from(publicPrice.toString()).mul(100000).div(E18).toNumber() /
    100000;

  const whitelistMintPrice =
    BigNumber.from(whiteListPrice.toString()).mul(100000).div(E18).toNumber() /
    100000;

  const node = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      return;
    }

    setIsUtilitiesModal(false);
  };

  const alert = [
    '',
    'Transaction Submitted!',
    'The transaction has not been executed',
    'Sale is not open yet',
  ];
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      width: '90%',
      maxWidth: '546px',
      borderRadius: '32px',
      maxHeight: '400px',
      height: '400px',
      background: '#232449',
      zIndex: '100',
      border: '6px solid #2C2D5A',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openBSCScan = () => {
    const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
    const BSC_URL =
      CHAIN_ID === '56'
        ? 'https://bscscan.com/tx/'
        : 'https://testnet.bscscan.com/tx/';
    const URL = BSC_URL + txHash;
    window.open(URL);
  };
  const onMintNFT = async () => {
    if (account && itemIndex !== 0) {
      let backUrl = process.env.REACT_APP_NFT_BACK_URL;
      setIsMinting(true);
      // const whiteListPrice = await magicNFTContract.methods
      if (publicSale) {
        if (!isDiscount) {
          try {
            const totalETH = publicPrice * itemIndex;
            const totalCost = BigNumber.from(totalETH.toString()).toString();
            const publicMint = await magicNFTContract.methods
              .publicMint(itemIndex)
              .send({
                from: account,
                value: totalCost,
                // gasPrice: ethers.utils.parseUnits('10', 'gwei'),
                // gas: 100000,
              });
            setAlertType(1);
            setModalIsOpen(true);
            setTxHash(publicMint.transactionHash);
          } catch (err) {
            console.log(err);
          }
        } else {
          try {
            const totalETH = publicPrice * itemIndex * (1 - discountPercentage);
            const totalCost = BigNumber.from(totalETH.toString())
              .mul(BigNumber.from(10).pow(12))
              .toString();

            const publicMint = await magicNFTContract.methods
              .publicMint(itemIndex)
              .send({
                from: account,
                value: totalCost,
                // gasPrice: ethers.utils.parseUnits('10', 'gwei'),
                // gas: 100000,
              });
            setAlertType(1);
            setModalIsOpen(true);
            setTxHash(publicMint.transactionHash);
          } catch (err) {
            console.log(err);
          }
        }
      }
      if (whitelistSale) {
        try {
          const response = await axios.post(backUrl, {
            address: account,
          });
          const signature = response.data.signature;
          const totalETH = whitelistMintPrice * 1000000 * itemIndex;
          const totalCost = BigNumber.from(totalETH.toString())
            .mul(BigNumber.from(10).pow(12))
            .toString();
          const whiteListedMint = await magicNFTContract.methods
            .whiteListMint(
              { userAddress: account, signature: signature },
              itemIndex,
            )
            .send({
              from: account,
              value: totalCost,
              // gasPrice: ethers.utils.parseUnits('10', 'gwei'),
              // gas: 100000,
            });
          setAlertType(1);
          setModalIsOpen(true);
          setTxHash(whiteListedMint.transactionHash);
        } catch (err) {
          if (err.code !== 4001) {
            setAlertType(2);
            setModalIsOpen(true);
          }
          console.log(err);
        }
      }
      if (!whitelistSale && !publicSale) {
        setAlertType(3);
        setModalIsOpen(true);
      }
      setIsMinting(false);
    }
  };

  async function isUserVC() {
    if (account) {
      const isVC = await magicNFTContract.methods.vcInfo(account).call();

      if (isVC > 0) {
        setIsDiscount(true);
      } else {
        setIsDiscount(false);
      }
    }
  }

  function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  useEffect(() => {
    async function getNFTAmountLeft() {
      try {
        // let magicAddress;
        // const currentCollection = localStorage.getItem('collection');
        // switch (currentCollection) {
        //   case 'genesis':
        //   default: {
        //     magicAddress = process.env.REACT_APP_GENESIS_CONTRACT_ADDRESS || '';
        //     break;
        //   }
        //   case 'revelation':
        //     magicAddress =
        //       process.env.REACT_APP_REVELATION_CONTRACT_ADDRESS || '';
        // }
        // console.log('methods', magicNFTContract.methods);
        // let web3 = new Web3(window.ethereum);
        // const magicNFTContractNew = new web3.eth.Contract(
        //   magicNFTContractJson.abi,
        //   magicAddress,
        // );
        const totalSupply = await magicNFTContract.methods.totalSupply().call();
        setNftAvailable(9999 - totalSupply);
      } catch (e) {
        console.log(e);
      }
    }

    if (magicNFTContract) {
      getNFTAmountLeft();
    } else {
      console.log('magicNFTContract not found');
    }
  }, [magicNFTContract, collection]);

  useEffect(() => {
    dispatch(fetchNFTMintAsync());
  }, [collection]);

  useEffect(() => {
    isUserVC();
  }, [account]);

  useEffect(() => {
    // console.log(isUtilitiesModal);
  }, [isUtilitiesModal]);

  const [img1, img2, img3, img5, img10] =
    collection === 'genesis'
      ? [
          <img alt="1 Character" src={nftItem1} />,
          <img alt="2 Characters" src={nftItem2} />,
          <img alt="3 Characters" src={nftItem3} />,
          <img alt="5 Characters" src={nftItem5} />,
          <img alt="10 Characters" src={nftItem10} />,
        ]
      : [
          <img
            style={{ maxHeight: '100%', borderRadius: 8 }}
            alt="1 Character"
            src={revelation1}
          />,
          <div style={{ display: 'flex', maxWidth: '100%', gap: 5 }}>
            <img
              style={{ maxHeight: '100%', borderRadius: 8 }}
              alt=""
              src={revelation1}
            />
            <img
              style={{ maxHeight: '100%', borderRadius: 8 }}
              alt=""
              src={revelation1}
            />
          </div>,
          <div
            style={{
              display: 'flex',
              maxWidth: '100%',
              maxHeight: '100%',
              gap: 5,
              position: 'relative',
            }}
          >
            <img
              style={{ borderRadius: 8, maxHeight: '100%', maxWidth: '100%' }}
              alt=""
              src={revelation1}
            />
            <span
              style={{
                position: 'absolute',
                bottom: '0%',
                left: '10%',
                color: 'white',
                fontSize: 30,
                fontWeight: 700,
                textShadow: '0px 0px 10px black',
              }}
            >
              3x
            </span>
          </div>,
          <div
            style={{
              display: 'flex',
              maxWidth: '100%',
              maxHeight: '100%',
              gap: 5,
              position: 'relative',
            }}
          >
            <img
              style={{ borderRadius: 8, maxHeight: '100%', maxWidth: '100%' }}
              alt=""
              src={revelation1}
            />
            <span
              style={{
                position: 'absolute',
                bottom: '0%',
                left: '10%',
                color: 'white',
                fontSize: 30,
                fontWeight: 700,
                textShadow: '0px 0px 10px black',
              }}
            >
              5x
            </span>
          </div>,
          <div
            style={{
              display: 'flex',
              maxWidth: '100%',
              maxHeight: '100%',
              gap: 5,
              position: 'relative',
            }}
          >
            <img
              style={{ borderRadius: 8, maxHeight: '100%', maxWidth: '100%' }}
              alt=""
              src={revelation1}
            />
            <span
              style={{
                position: 'absolute',
                bottom: '0%',
                left: '10%',
                color: 'white',
                fontSize: 30,
                fontWeight: 700,
                textShadow: '0px 0px 10px black',
              }}
            >
              10x
            </span>
          </div>,
        ];

  const openHelpModal = () => {
    setIsHelpModalOpen(true);
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };

  const closeHelpModal = () => {
    setIsHelpModalOpen(false);
    document.body.style.overflowY = 'unset';
    document.body.style.overflowX = 'hidden';
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <img alt="" src={alertType === 1 ? iconWallet : iconError} />
        <Message> {alert[alertType]}</Message>
        <Buttons>
          {alertType === 1 && (
            <BSCButton onClick={openBSCScan}>View in Actions</BSCButton>
          )}
          <CloseButton onClick={closeModal}>Close</CloseButton>
        </Buttons>
      </Modal>

      {isHelpModalOpen && (
        <HelpModal isModalOpen={isHelpModalOpen} closeModal={closeHelpModal} />
      )}

      <div className="nftmint">
        <div
          className={`nftmint__banner-container ${
            collection === 'revelation' ? '--blazy' : ''
          }`}
        >
          {isUtilitiesModal ? (
            <div className="nftmint__question-modal" ref={node}>
              <div className="nftmint__question-modal__icon">
                <AiOutlineClose onClick={() => setIsUtilitiesModal(false)} />
              </div>
              <ul className="nftmint__list">
                <li>
                  Earn portion of distribution of 10% lobby fees to genesis nft
                  holders (coming soon)
                </li>
                <li>NFT character can be played in the game</li>
                <li>NFT profit share with NFT and players (coming soon)</li>
                <li>
                  Characters earn money for all nft holders of that character
                  (coming soon)
                </li>
              </ul>
            </div>
          ) : null}
          <div>
            <h1>Mint NFT</h1>
            <div className="nftmint__grouping__row">
              <h5>Utilities for owning NFTs:</h5>
              {!breakpoint.sm ? (
                <QuestionCircleFill
                  onClick={() => setIsUtilitiesModal(!isUtilitiesModal)}
                />
              ) : null}
            </div>
            {breakpoint.sm ? (
              <ul className="nftmint__list">
                <li>
                  Earn portion of distribution of 10% lobby fees to genesis nft
                  holders (coming soon)
                </li>
                <li>NFT character can be played in the game</li>
                <li>NFT profit share with NFT and players (coming soon)</li>
                <li>
                  Characters earn money for all nft holders of that character
                  (coming soon)
                </li>
              </ul>
            ) : null}
            <h5>Choose desired amount of NFTs to mint!</h5>
            {!breakpoint.sm ? (
              <h4 onClick={() => setItemIndex(10)}>
                Buy 10 and get 1 free NFT
              </h4>
            ) : null}
          </div>
          {breakpoint.sm ? (
            <div className="nftmint__grouping__col">
              <div className="nftmint__available-container">
                <h5 className="nftmint__available-container__text">
                  <GiBlackKnightHelm className="icon" />
                  Available amount:
                </h5>
                <h5>
                  {nftAvailable ? (
                    nftAvailable + ' / 9999 NFTs'
                  ) : (
                    <i className="fa fa-spinner fa-spin" />
                  )}
                </h5>
              </div>
              <h4 onClick={() => setItemIndex(10)}>
                Buy 10 and get 1 free NFT
              </h4>
            </div>
          ) : null}
        </div>
        {breakpoint.sm ? null : (
          <div className="nftmint__available-container">
            <h5 className="nftmint__available-container__text">
              <GiBlackKnightHelm className="icon" />
              Available amount:
            </h5>
            <h5>
              {nftAvailable ? (
                nftAvailable + ' / 9999 NFTs'
              ) : (
                <i className="fa fa-spinner fa-spin" />
              )}
            </h5>
          </div>
        )}
        {!breakpoint.xl && (
          <Button
            variant="subtle"
            className="!w-[auto] mb-4"
            onClick={showRamp}
          >
            Buy BNB with FIAT
          </Button>
        )}
        <CollectionButton />
        <div className="nftmint__itemList">
          <div
            className={
              itemIndex === 1
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            onClick={() => setItemIndex(1)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img1}</div>
            <div className="nftmint__itemList--item--title">
              <h3>1 Character</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale && publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale && publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>
          <div
            className={
              itemIndex === 2
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            onClick={() => setItemIndex(2)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img2}</div>
            <div className="nftmint__itemList--item--title">
              <h3>2 Characters</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale &&
                      2 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        2 *
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale &&
                    2 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  2 * whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>
          <div
            className={
              itemIndex === 3
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            onClick={() => setItemIndex(3)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img3}</div>
            <div className="nftmint__itemList--item--title">
              <h3>3 Characters</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale &&
                      3 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        3 *
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale &&
                    3 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  3 * whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>
          <div
            className={
              itemIndex === 5
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            onClick={() => setItemIndex(5)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img5}</div>
            <div className="nftmint__itemList--item--title">
              <h3>5 Characters</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale &&
                      5 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        5 *
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale &&
                    5 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  5 * whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>
          <div
            className={
              itemIndex === 10
                ? 'nftmint__itemList--itemSelected'
                : ' nftmint__itemList--item'
            }
            // className="nftmint__itemList--itemSold"
            onClick={() => setItemIndex(10)}
          >
            {isDiscount ? (
              <img
                src={discount}
                alt="discount"
                className="nftmint__itemList--item__discount"
              />
            ) : null}
            <div className="nftmint__itemList--item--icon">{img10}</div>
            <div className="nftmint__itemList--item--title">
              <h3>10 Characters</h3>
              {isDiscount ? (
                <div className="nftmint__itemList--item__price-container">
                  <h5 className="nftmint__itemList--item__discount-price">
                    {publicSale &&
                      10 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                  </h5>
                  <h5>
                    {publicSale &&
                      (
                        10 *
                        Number(publicMintPrice.toString()) *
                        (1 - discountPercentage)
                      ).toFixed(2) +
                        '\xa0' +
                        ' BNB'}
                  </h5>
                </div>
              ) : (
                <h5>
                  {publicSale &&
                    10 * publicMintPrice.toString() + '\xa0' + ' BNB'}
                </h5>
              )}
              <h5>
                {whitelistSale &&
                  10 * whitelistMintPrice.toString() + '\xa0' + 'BNB'}
              </h5>
            </div>
          </div>
        </div>

        {!breakpoint.xl && itemIndex > 0 && (
          <div className="nftmint__button_mobile">
            <div className="nftmint__button_mobile--info">
              <h3>
                {itemIndex} {itemIndex === 1 ? 'Character' : 'Characters'}
              </h3>
              {publicSale ? (
                isDiscount ? (
                  <h5>
                    {(
                      itemIndex *
                      publicMintPrice *
                      (1 - discountPercentage)
                    ).toFixed(2)}{' '}
                    BNB
                  </h5>
                ) : (
                  <h5>{itemIndex * publicMintPrice} BNB</h5>
                )
              ) : null}
              {whitelistSale && <h5>{itemIndex * whitelistMintPrice} BNB</h5>}
            </div>
            {account ? (
              isMinting ? (
                <button disabled>
                  <span className="d-flex flex-row justify-content-center align-items-center">
                    <i className="fa fa-spinner fa-spin"></i>
                    <div className="ms-2">Mint</div>
                  </span>
                </button>
              ) : (
                <button
                  style={{ padding: '1rem 2rem', width: 'auto' }}
                  onClick={onMintNFT}
                >
                  Mint
                </button>
              )
            ) : (
              <button onClick={onPresentConnectModal}>Mint</button>
            )}
          </div>
        )}
        {breakpoint.xl && (
          <div className="nftmint__button">
            <div className="flex gap-4 mt-2">
              {itemIndex > 0 ? (
                account ? (
                  isMinting ? (
                    <button disabled>
                      <span className="d-flex flex-row justify-content-center align-items-center">
                        <i className="fa fa-spinner fa-spin"></i>
                        <div className="ms-2">Mint</div>
                      </span>
                    </button>
                  ) : (
                    <button onClick={onMintNFT}>Mint</button>
                  )
                ) : (
                  <button onClick={onPresentConnectModal}>Mint</button>
                )
              ) : (
                <button
                  disabled
                  style={{
                    background: '#333455',
                    color: '#212249',
                    cursor: 'not-allowed',
                  }}
                >
                  Choose Amount
                </button>
              )}
              <button onClick={showRamp}>Buy BNB with FIAT</button>
            </div>
          </div>
        )}
      </div>

      <GasFeeHelpContainer
        content="Experiencing high gas fees?"
        openStakingHelpModal={openHelpModal}
        position="center"
      />
    </>
  );
};

const Message = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

const BSCButton = styled.button`
  background: #316bff;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 165px;
  height: 56px;
  border-width: 0px;
  height: 56px;
  width: 165px;
  padding: 18px, 36px, 18px, 36px;
  margin-right: 1rem;
`;

const CloseButton = styled.button`
  height: 56px;
  width: 164px;
  border-radius: 8px;
  padding: 18px, 36px, 18px, 36px;
  border: 2px solid #76779a;
  border-radius: 8px;
  background: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #76779a;
`;
export default NFTMint;
